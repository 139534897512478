import React from 'react';
import PropTypes from 'prop-types';

const TrashThin = ({
  className,
  fill = 'currentColor',
  size = 16,
  viewBox = '0 0 24 24'
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.125 18.001C3.575 18.001 3.10417 17.8051 2.7125 17.4135C2.32083 17.0218 2.125 16.551 2.125 16.001V3.00098H1.125V1.00098H6.125V0.000976562H12.125V1.00098H17.125V3.00098H16.125V16.001C16.125 16.551 15.9292 17.0218 15.5375 17.4135C15.1458 17.8051 14.675 18.001 14.125 18.001H4.125ZM14.125 3.00098H4.125V16.001H14.125V3.00098ZM6.125 14.001H8.125V5.00098H6.125V14.001ZM10.125 14.001H12.125V5.00098H10.125V14.001Z"
        fill={fill}
      />
    </svg>
  );
};

TrashThin.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
  viewBox: PropTypes.string
};

export default TrashThin;
