import React from 'react';
import PropTypes from 'prop-types';

const PencilThin = ({
  className,
  fill = 'currentColor',
  size = 16,
  viewBox = '0 0 24 24'
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_101124_1181)">
        <path
          d="M2 16.001H3.425L13.2 6.22598L11.775 4.80098L2 14.576V16.001ZM0 18.001V13.751L13.2 0.575977C13.4 0.392643 13.6208 0.250977 13.8625 0.150977C14.1042 0.0509766 14.3583 0.000976562 14.625 0.000976562C14.8917 0.000976562 15.15 0.0509766 15.4 0.150977C15.65 0.250977 15.8667 0.400977 16.05 0.600977L17.425 2.00098C17.625 2.18431 17.7708 2.40098 17.8625 2.65098C17.9542 2.90098 18 3.15098 18 3.40098C18 3.66764 17.9542 3.92181 17.8625 4.16348C17.7708 4.40514 17.625 4.62598 17.425 4.82598L4.25 18.001H0ZM12.475 5.52598L11.775 4.80098L13.2 6.22598L12.475 5.52598Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_101124_1181">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

PencilThin.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
  viewBox: PropTypes.string
};

export default PencilThin;
