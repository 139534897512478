import React from 'react';
import PropTypes from 'prop-types';

const CheckThin = ({
  className,
  fill = 'currentColor',
  size = 16,
  viewBox = '0 0 24 24'
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.29448 15.5291L0 9.23466L1.57362 7.66104L6.29448 12.3819L16.4264 2.25L18 3.82362L6.29448 15.5291Z"
        fill={fill}
      />
    </svg>
  );
};

CheckThin.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
  viewBox: PropTypes.string
};

export default CheckThin;
